/*********************************************************************/
$(document).ready(function() {
    $('#authorizationText').scroll(function () {
        if (($(this)[0].scrollHeight - $(this)[0].scrollTop) === $(this)[0].clientHeight){
            $('#consentText1').prop('disabled', false);
            $('#consentText2').prop('disabled', false);
            $('#consentText3').prop('disabled', false);
            $('#consentText4').prop('disabled', false);
            $('#consentText5').prop('disabled', false);
            $('#momentumSTIPrivacyPolicy').prop('disabled', false);
        }
    });
});